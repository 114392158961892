@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: rgb(240, 253, 244);
}


.map-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .map {
    height: 350px;
    width: 350px;
    margin: 30px;
    border: 1px solid gray;
    background-color: rgb(125, 193, 220);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    border-radius: 5px;
  }




/* --- (4) Leaflet container with map dimensions (other properties optional) --- */
.leaflet-container {
    width: 350px;
    height: 350px;
    border-radius: 5px;
  }
  
  .leaflet-control-attribution {
    font-size: 10px;
  }